import React, { useState } from "react";
import Card from "../../Base/Card";
import "./style.scss";
import { plusIcon } from "src/assets";
import { CustomModal } from "../../Layout";
import { ReactComponent as CloseBtnICon } from "./../../../../assets/icons/closeBtnIcon.svg";
import { Button } from "../../Base";

const TipsSection: React.FC = () => {
  const [isShowModal, setShowModal] = useState(false);
  // const [modalData, setModalData] = useState({ title: "", message: "" });

  const showModal = () => {
    setShowModal(true);
  };
  const items = [
    { text: "Update your reel", onClick: showModal },
    { text: "Verify your Prodile", onClick: showModal },
    { text: "Link your Profile", onClick: showModal },
  ];

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="tipsSectionMain">
      <h4 className="titleText">Tips</h4>
      {/* <div className="tipsCardsMain"> */}
      <div
        // type="button"
        className="tipsCardWrapper "
      >
        {items.map((item, index) => (
          <Card
            key={index}
            className="tipsCard"
          >
            <Button
              onClick={item.onClick}
              className="btn-undefind
              "
            >
              <div className="body">
                <div
                  className="addButton"
                  // onClick={item.onClick}
                >
                  <img
                    src={plusIcon}
                    alt=""
                  />
                </div>
                <h3 className="text">{item.text}</h3>
              </div>
            </Button>
          </Card>
        ))}
      </div>
      {/* </div> */}
      {isShowModal && (
        <CustomModal
          handleClose={handleClose}
          show={isShowModal}
          className="customModalCard"
        >
          <div className="customCard">
            <Button
              className="close-btn"
              type="button"
              onClick={handleClose}
            >
              <CloseBtnICon />
            </Button>
            <p>
              Updating your reel yearly will make your profile more attractive which helps you find a wide-rande of
              clients
            </p>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default TipsSection;

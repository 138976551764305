import React from "react";
import Card from "../../Base/Card";
import EmptyList from "../../Base/EmptyList";
// import { TbFilePencil } from "react-icons/tb";
import "./style.scss";
// import { LiaFontAwesomeFlag } from "react-icons/lia";
// import { GrAttachment } from "react-icons/gr";
// import { profileIcon as ProfileIcon } from "src/assets";
import { ReactComponent as ProfileIcon } from "./../../../../assets/images/profileIcon.svg";
import { ReactComponent as FlagIcon } from "./../../../../assets/icons/flagIcon.svg";
import { ReactComponent as UploadIcon } from "./../../../../assets/icons/uploadIcon.svg";
import { ReactComponent as NoteText } from "./../../../../assets/icons/noteText.svg";

interface Task {
  id?: number;
  title?: string;
  status?: string;
  logo?: string;
  createdAt?: string;
  createdBy?: string;
  attachments?: number;
  dueDate?: string;
  production?: string;
}

interface TaskListProps {
  tasks: Task[]; // Array of Task objects
}
const getClassByStatus = (status: string) => {
  console.log("status", status);
  let className = "";
  if (status == "Complete") {
    className = "green";
  } else if (status == "In-Progress") {
    className = "yellowWarning";
  } else if (status == "Not Started") {
    className = "skayblue";
  } else if (status == "Cancelled") {
    className = "red";
  }
  return className;
};

const TaskList: React.FC<TaskListProps> = ({ tasks }) => {
  return (
    <>
      {tasks.length > 0 ? (
        <div className="taskListMain">
          {tasks.map((task, index) => {
            return (
              <Card
                key={index}
                className="taskListCard"
              >
                <div className="row">
                  <div className="production-text">
                    <p>Production: {task.production}</p>
                    <div className="logo">
                      <ProfileIcon />
                    </div>
                  </div>
                  <div className="uploadSelects">
                    <div className="upload-text">
                      <h2 className="">Upload selects from photoshoot</h2>
                      <div className="info-section">
                        <div className="text-info">
                          {/* <span className="flag-Icon">
                            <FlagIcon />
                          </span> */}
                          <span className={`flag-Icon ${getClassByStatus(task.status ?? "")}`}>
                            <FlagIcon />
                          </span>
                          Complete by <span className="today-color"> today</span> 10:00am
                        </div>
                        <div className="text-info">
                          <UploadIcon /> 2 attachments
                        </div>
                      </div>
                    </div>
                    <div className="selectBox">
                      <span className="work-log"></span>
                      <p>{task.status}</p>
                      <input type="checkbox" />
                    </div>
                  </div>

                  <div className="Info-client">
                    <p>{`By ${task.createdBy} On ${task.createdAt}`}</p>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      ) : (
        <EmptyList
          text="No tasks yet."
          icon={<NoteText />}
          className="emptyTaskCard"
        />
      )}
    </>
  );
};

export default TaskList;

// const taskList = [
//   {
//     id: 1,
//     title: "Upload selects from photoshoot",
//     production: "IZZO music videoshoot",
//     logo: "",
//     dueDate: "",
//     status: "Complete",
//     craetedBy: "Parves Ahamad",
//     createdAt: "Feb 29",
//     attachments: 2,
//   },
//   {
//     id: 2,
//     title: "Upload selects from photoshoot",
//     production: "IZZO music videoshoot",
//     logo: "",
//     dueDate: "",
//     status: "Complete",
//     craetedBy: "Parves Ahamad",
//     createdAt: "Feb 29",
//     attachments: 2,
//   },
// ];

import AddNewButton from "../../Base/addNewButton";
import TeamMembesList from "../../components/TeamMemberList";

const TeamMemberWidget = () => {
  const teamMembetCount = 2;

  return (
    <div className="taskWidgetMain">
      <div className="taskHeader">
        <h4 className="title">Team Member ({teamMembetCount})</h4>
      </div>
      <TeamMembesList members={[]} />

      <AddNewButton
        text="Add team member"
        type="button"
        onClick={() => console.log("Ad new Team member")}
      />
    </div>
  );
};

export default TeamMemberWidget;

// { id: 1, status: "Pending", logo: "" }

import EmptyList from "../../Base/EmptyList";
import "./style.scss";
import { ReactComponent as NotificationIcon } from "./../../../../assets/icons/notificationIcon.svg";

// Define the type for a notification
interface Notification {
  message: string;
  date: string;
}

// Define the props type for the component
interface NotificationListProps {
  notifications: Notification[];
}

const NotificationList: React.FC<NotificationListProps> = ({ notifications }) => {
  return (
    <>
      {notifications.length > 0 ? (
        <div className="notifications-list">
          {notifications.map((notification, index) => (
            <div
              className="notification"
              key={index}
            >
              <span className="ntf-border"></span>
              <div className="dot active"></div>
              <div className="content">
                <p className="message">
                  {notification.message} <span></span>
                </p>
                <p className="date">{notification.date}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyList
          text="No notifications yet."
          icon={<NotificationIcon />}
          className="emptyNotification"
        />
      )}
    </>
  );
};

export default NotificationList;

// <div className="notifications-container">
// <h2>Notifications</h2>
// <div className="notifications-list">
//   <div className="notification">
//     <span className="ntf-border"></span>
//     <div className="dot active"></div>
//     <div className="content">
//       <p className="message">
//         You have a <span>message</span>
//       </p>
//       <p className="date">Feb 8, 2024 / 7:15 PM</p>
//     </div>
//   </div>
//   <div className="notification">
//     <span className="ntf-border"></span>
//     <div className="dot"></div>
//     <div className="content">
//       <p className="message">Joe Studio sent form Graphic Design Brief to Jane Studio.</p>
//       <p className="date">Feb 8, 2024 / 7:15 PM</p>
//     </div>
//   </div>
//   <div className="notification">
//     <span className="ntf-border"></span>
//     <div className="dot"></div>
//     <div className="content">
//       <p className="message">
//         You have a <span>message</span>
//       </p>
//       <p className="date">Feb 8, 2024 / 7:15 PM</p>
//     </div>
//   </div>
//   <div className="notification">
//     <span className="ntf-border"></span>
//     <div className="dot"></div>
//     <div className="content">
//       <p className="message">
//         You have a <span>message</span>
//       </p>
//       <p className="date">Feb 8, 2024 / 7:15 PM</p>
//     </div>
//   </div>
//   <div className="notification">
//     <span className="ntf-border"></span>
//     <div className="dot"></div>
//     <div className="content">
//       <p className="message">
//         You have a <span>message</span>
//       </p>
//       <p className="date">Feb 8, 2024 / 7:15 PM</p>
//     </div>
//   </div>
// </div>
// </div>

import { useSelector } from "react-redux";
import PaymentDashboard_ClientsDefault from "src/components/features/PaymentDashboard_Clients";
import PaymentDashboard_CreatorDefault from "src/components/features/PaymentDashboard_Creator";
import PaymentDashboard_SuperProducer_Default from "src/components/features/PaymentDashboard_SuperProducer";
import { RootState } from "src/store";

const Payment = () => {
  const type = useSelector((state: RootState) => state.auth.type);
  switch (type) {
    case "User":
      return <PaymentDashboard_ClientsDefault />;
    case "Creator":
      return <PaymentDashboard_CreatorDefault />;
    case "Super Producer":
      return <PaymentDashboard_SuperProducer_Default />;
    default:
      return <PaymentDashboard_ClientsDefault />;
  }
};
export default Payment;

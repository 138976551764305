// Dropdown.tsx
import React from "react";
import Select, { StylesConfig, SingleValue, ActionMeta } from "react-select";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  onChange: (option: SingleValue<Option>, actionMeta: ActionMeta<Option>) => void;
  placeholder: string;
}
const customStyles: StylesConfig<Option, false> = {
  control: (base) => ({
    ...base,
    backgroundColor: "#2E3440", // Background color
    color: "#ECEFF4", // Font color
    borderColor: "#4C566A", // Border color
    borderWidth: 1, // Border width
    borderRadius: 0, // Remove rounded corners
    boxShadow: "none", // Remove box shadow
    minHeight: "auto", // Adjust height to be consistent
    height: "35px", // Set fixed height
    cursor: "pointer", // Set cursor
  }),
  singleValue: (base) => ({
    ...base,
    color: "#ECEFF4", // Font color
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#08293C", // Menu background color
    borderRadius: "16px", // Remove rounded corners
    marginTop: "4px", // Adjust top margin
    padding: "10px ",
    width: "154px",
    border: "0.5px solid #435661",
  }),
  option: (base, state) => ({
    ...base,
    // padding: "0px",
    border: "1px solide white",
    backgroundColor: state.isSelected ? "#81A1C1" : "#08293C", // Selected option background
    color: state.isSelected ? "#08293C" : "#ECEFF4", // Font color
    "&:hover": {
      backgroundColor: "transparent", // Hover background color
      color: "#FFFFFF", // Hover font color
    },
    padding: "5px 10px", // Adjust padding
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#ECEFF4", // Dropdown indicator color
    "&:hover": {
      color: "#08293C", // Dropdown indicator color on hover
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "#08293C", // Indicator separator color
  }),
};

const Dropdown: React.FC<DropdownProps> = ({ options, onChange, placeholder }) => {
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      styles={customStyles}
      components={{ IndicatorSeparator: () => null }} // Remove indicator separator
    />
  );
};

export default Dropdown;

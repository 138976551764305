import { Button } from "src/components/common/Base";
import "./styles.scss";
import ActionCards from "src/components/common/Widgets/ActionCard";
import TipsSection from "src/components/common/Widgets/TipsSection";
import TaskWidget from "src/components/common/Widgets/TaskWidget";
import { MdWindow } from "react-icons/md";
import Card from "src/components/common/Base/Card";
// import TeamMemberWidget from "src/components/common/Widgets/TeamMemberWidget";
import ProductionWidget from "src/components/common/Widgets/productionWidget";
import CalendarComponent from "src/components/common/Widgets/Calender";
import AddNewButton from "src/components/common/Base/addNewButton";
import useDeviceStatus from "src/utils/responsive";
import NotificationWidget from "src/components/common/Widgets/NotificationWidget";

const Dashboard_CreatorDefault = () => {
  const { isDesktop } = useDeviceStatus();
  return (
    <div className="dashboardMain">
      <div className="dashboardHeader">
        <div className="deshbord-header-text">
          <p>Thursday, March 28th</p>
          <h1>
            Your <span className="heading-text-bold">dashboard.</span>{" "}
            <span className="heading-text-light">Let’s win baby.</span>
          </h1>
        </div>
        <div>
          <Card className="customizeButtonCard">
            <Button
              type="button"
              className="customizeButton"
            >
              <span className="icon">
                <MdWindow color="#ffffff" />
              </span>
              <span className="text">Customize</span>
            </Button>
          </Card>
        </div>
      </div>
      <ActionCards />
      <TipsSection />

      <div className="task-main-left">
        <div className="task-left-container">
          <TaskWidget />
          {!isDesktop && (
            <>
              <CalendarComponent />
              <NotificationWidget />
            </>
          )}

          {/* <TeamMemberWidget /> */}
          <ProductionWidget />
          <AddNewButton
            text="Add area to dashboard"
            type="button"
            className="add-deshboard-area"
            onClick={() => console.log("clicked")}
          />
        </div>
        {isDesktop && (
          <div className="task-right-container">
            <CalendarComponent />
            <NotificationWidget />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard_CreatorDefault;

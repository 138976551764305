import { useSelector } from "react-redux";
import Dashboard_ClientsDefault from "src/components/features/Dashboard_Clients";
import Dashboard_CreatorDefault from "src/components/features/Dashboard_Creator";
import Dashboard_SuperProducerDefault from "src/components/features/Dashboard_SuperProducer";
import { RootState } from "src/store";

const Dashboard = () => {
  const type = useSelector((state: RootState) => state.auth.type);
  switch (type) {
    case "User":
      return <Dashboard_ClientsDefault />;
    case "Creator":
      return <Dashboard_CreatorDefault />;
    case "Super Producer":
      return <Dashboard_SuperProducerDefault />;
    default:
      return <Dashboard_ClientsDefault />;
  }
};

export default Dashboard;
